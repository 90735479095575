import * as React from 'react';
import Header from '../components/Common/Header';
import Intro from '../components/ChildrenBooks/Intro';
import Cards from '../components/ChildrenBooks/Cards';
import Layout from '../components/Layout/index';
import { Seo } from '../components/Seo';

const ResourcePage = () => {
  return (
    <Layout>
      <Seo
        title="Children books | National Embryo Donation Center"
        description="If you're looking for children's books to help explain embryo adoption to some of the youngest among us, here are some good offerings"
      />
      <div>
        <Header
          title="Children's Books on Embryo Adoption from the NEDC"
          description="How did I get here?"
          video={'https://www.youtube.com/watch?v=z4xKC7CpOZ8'}
          background={'/images/header/chayene-rafaela-nGwkIZFelko-unsplash.png'}
        />
        <Intro />
        <section>
          <Cards />
        </section>
      </div>
    </Layout>
  );
};

export default ResourcePage;
