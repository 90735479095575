/** @jsx jsx */
import { jsx } from 'theme-ui';

const Cards = () => {
  const data = [
    {
      title: 'Chosen & Loved',
      description: 'Click below to purchase your copy of Chosen & Loved.',
      link: 'https://www.amazon.com/Chosen-Loved-Story-Life-Living/dp/0997698276/ref=sr_1_1?crid=3SC1W8HXWNJKW&amp;keywords=chosen+and+loved+by+sharon+kazmierczak&amp;qid=1648488989&amp;sprefix=%22Chosen+and+Loved%22%2Caps%2C88&amp;sr=8-1',
    },
    {
      title: 'Made With Love',
      description: 'Click below to purchase your copy from Amazon.',
      link: 'https://www.amazon.com/Made-Love-sweetest-allegory-donation/dp/169691714X/ref=mp_s_a_1_2?keywords=made+with+love+embryo&amp;qid=1574268608&amp;sr=8-2',
    },
    {
      title: 'Treasure Babies',
      description: 'Click below to purchase your copy from Amazon.',
      link: 'https://www.amazon.com/Treasure-Babies-under-sea-families/dp/1537151541/ref=sr_1_1?keywords=treasure+babies&amp;qid=1556910435&amp;s=gateway&amp;sr=8-1',
    },
    {
      title: 'The Best Thing is You',
      description: 'Click below to purchase your copy from Amazon.',
      link: 'https://www.amazon.com/Best-Thing-You-Ambre-Williams/dp/B0CS61F7CR/ref=sr_1_1?crid=1V1TSP5O5WEVM&dib=eyJ2IjoiMSJ9.ck4B6pzBXb8y8ysNfDb--1OiGEMQhzfsTyZ4AZUulJEVFLrGTYLMFFEyVzFpxzNjHw1wgEhjwVnTf33nhHkfkCzfytsb1UYxy89VaNJwkM7uaP8QfFqWVoe68IHhYwcNp1SZf3mAtz0rf-kMbobdRdh1nCR22Ys_Nj7yDsoXUVKtULfp1ZCRKWbBEFZ7OQLcGoyx3XawIr0jnoev9J7u8oW3kdzBothpzenAPdyqPh0.Dd-0GG8eog55OF7FKzlBjKPE7wVr2c7wWD6gLwLdHjY&dib_tag=se&keywords=the+best+thing+is+you&qid=1712688645&s=books&sprefix=the+best+thing+is+you%2Cstripbooks%2C95&sr=1-1',
    },
    {
      title: 'The Greatest Gift: An Embryo Adoption Story',
      description: 'Click below to purchase your copy from Amazon.',
      link: 'https://www.amazon.com/Greatest-Gift-Embryo-Adoption-Story/dp/B0BQPMCPTB',
    },
    {
      title: 'The Miracle of You',
      description: 'Click below to purchase your copy from Amazon.',
      link: 'https://www.amazon.com/Miracle-You-Savannah-Hansen/dp/B0BQPSM3QK',
    }
  ];
  return (
    <section
      sx={{
        background: '#EFF9F8',
      }}
      id="books"
    >
      <div
        className="content"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: ' 2.5rem',
          flexWrap: 'wrap',
        }}
      >
        {data.map((item) => (
          <div
            key={item.title}
            sx={{
              background: '#FFFFFF',
              borderRadius: '10px',
              maxWidth: '360px',
              p: '2rem',
            }}
          >
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem',
              }}
            >
              <h4
                sx={{
                  variant: 'text.lg',
                  textAlign: 'center',
                  color: '#101828',
                }}
              >
                {item.title}
              </h4>
              <p
                sx={{
                  fontStyle: 'normal',
                  variant: 'text.normal',
                  textAlign: 'center',
                  color: '#667085',
                }}
              >
                {item.description}
              </p>
              <a
                href={item.link}
                target="_blank"
                title="Buy Now"
                rel="noopener noreferrer"
                sx={{
                  variant: 'text.normal',
                  color: '#FFFFFF',
                  textAlign: 'center',
                  background: '#8BBFBD',
                  padding: '12px 20px',
                  borderRadius: '0.25rem',
                  border: '1px solid #8BBFBD',
                  '&:hover': {
                    background: '#fff',
                    color: '#2E6D6A',
                  },
                }}
              >
                Buy Now
              </a>
            </div>
          </div>
        ))}
        <div sx={{
          fontSize: '1.25rem',
          color: '#282828',
          a: {
            textDecoration: 'underline',
          },
        }}>
        </div>
      </div>
    </section>
  );
};

export default Cards;
