/** @jsx jsx */
import { jsx } from 'theme-ui';
import SectionHeader from '../Common/SectionHeader';

const Intro = () => {
  return (
    <section>
      <div
        className="text-content"
        sx={{
          mb: '3rem',
        }}
      >
        <SectionHeader heading="Children's Books" styles={{ pt: [null, null, null, '3rem'] }} />
        <div
          sx={{
            p: {
              mb: '1.5rem',
              variant: 'text.normal',
            },
          }}
        >
          <p>
            As awareness and interest in donating and adopting frozen embryos increases, the
            question of “how do we or should we tell the children?” begins to arise. This page
            features children’s books that can help answer that question. While many of the
            circumstances surrounding traditional adoption and embryo adoption are similar, embryo
            adoption is still very distinct because it is so much more personal and unique.
          </p>
          <p>
            Children’s books on traditional adoption are plentiful and help to reinforce to the
            child why they are special and loved. Some of these could have crossover value to the
            parents of embryo adopted children, but they don’t tell the whole story. That’s why we
            are happy to make you aware of resources geared specifically toward embryo adopted
            children.
          </p>
          <p>
            <a href="https://www.amazon.com/Chosen-Loved-Story-Life-Living/dp/0997698276">Chosen & Loved</a>, written by NEDC embryo adoption mom Shari Kazmierczak, tells the
            delightful story of a bear and bunny who present the concept of embryo adoption to young
            readers. In this keepsake book, there are several pages you can personalize with your
            own pictures. That way, this becomes your child’s story about how he/she became a part
            of their forever family. Instructions and photo tabs are included with each book. It is
            available on Amazon.
          </p>
          <p>
            <a href="https://www.amazon.com/Treasure-Babies-under-sea-families/dp/1537151541/ref=pd_lpo_sccl_2/133-1689286-0878169?pd_rd_w=DGfsS&content-id=amzn1.sym.1ad2066f-97d2-4731-9356-36b3edf1ae04&pf_rd_p=1ad2066f-97d2-4731-9356-36b3edf1ae04&pf_rd_r=AY1CVGKSGMQ55E0BS8QB&pd_rd_wg=OYEOa&pd_rd_r=70cfe942-b80d-4f64-8a5a-06ced5fc07c1&pd_rd_i=1537151541&psc=1">Treasure Babies</a> invites you to join Strive, Ora, and their fishy friends as they
            struggle against the tide through the pain of infertility and discover the hope that
            doctors and embryo adoption can bring, the miracle of new life, and the beauty of shared
            treasure. Written and illustrated by two moms who’ve been there, this book, which
            includes a dedication page, family discussion questions, and a designated area to share
            your own child’s story, is sure to be a helpful tool for years to come. It is available
            on Amazon.
          </p>
          <p>
            Another offering we recommend is <a href="https://www.amazon.com/Made-Love-sweetest-allegory-donation/dp/169691714X/ref=pd_lpo_sccl_1/133-1689286-0878169?pd_rd_w=DGfsS&content-id=amzn1.sym.1ad2066f-97d2-4731-9356-36b3edf1ae04&pf_rd_p=1ad2066f-97d2-4731-9356-36b3edf1ae04&pf_rd_r=AY1CVGKSGMQ55E0BS8QB&pd_rd_wg=OYEOa&pd_rd_r=70cfe942-b80d-4f64-8a5a-06ced5fc07c1&pd_rd_i=169691714X&psc=1">Made With Love</a>. Explaining embryo donation and adoption
            to young children can be hard. But Made With Love endeavors to make it easy by using an
            allegory virtually every child can understand: baking cookies! A visual feast with a
            rhyming storyline, donating and adopting families alike can eat up this heartwarming
            tale. It is available on Amazon.
          </p>
          <p>
            We also recommend <a href="https://www.amazon.com/Best-Thing-You-Ambre-Williams/dp/B0CS61F7CR/ref=sr_1_1?crid=1V1TSP5O5WEVM&dib=eyJ2IjoiMSJ9.ck4B6pzBXb8y8ysNfDb--1OiGEMQhzfsTyZ4AZUulJEVFLrGTYLMFFEyVzFpxzNjHw1wgEhjwVnTf33nhHkfkCzfytsb1UYxy89VaNJwkM7uaP8QfFqWVoe68IHhYwcNp1SZf3mAtz0rf-kMbobdRdh1nCR22Ys_Nj7yDsoXUVKtULfp1ZCRKWbBEFZ7OQLcGoyx3XawIr0jnoev9J7u8oW3kdzBothpzenAPdyqPh0.Dd-0GG8eog55OF7FKzlBjKPE7wVr2c7wWD6gLwLdHjY&dib_tag=se&keywords=the+best+thing+is+you&qid=1712688645&s=books&sprefix=the+best+thing+is+you%2Cstripbooks%2C95&sr=1-1">The Best Thing is You</a>, a heartfelt story by NEDC mom Ambre Williams that takes you on a journey of how one family came to be with the help of doctors and a special gift. Through magical storytelling and captivating illustrations, it conveys a powerful message that the love families share can transcend genetic connections.
          </p>
          <p>In <a href='https://www.amazon.com/Greatest-Gift-Embryo-Adoption-Story/dp/B0BQPMCPTB'>The Greatest Gift: An Embryo Adoption Story</a>, written by NEDC embryo adoption mom Savannah Hansen, the Hayes family is looking to grow a garden, but something isn’t quite right. There seems to be darkness where they thought there would only be light. To move forward, what will be their decision? Follow along on their adventure of trial and error, heartbreak and waiting, and ultimately the greatest gift they could ever be given. Maybe it won’t follow tradition, but ultimately this was how it was meant to be. Can their friends, the Eatons, help them in this time of need?</p><br />
          <p>Another offering by Savannah Hansen is <a href='https://www.amazon.com/Miracle-You-Savannah-Hansen/dp/B0BQPSM3QK'>The Miracle of You</a>. Here’s a description from the publisher: Your story, little one, is something quite neat. The journey to meet you was very unique. Some mommies and daddies need help from above to have little babies they already love. God’s timing is perfect. He makes no mistakes. He formed you perfectly and uniquely just like every snowflake. This story is to show you how much you are loved. There is no question that you are a miracle sent from above.</p>
        </div>
      </div>
    </section>
  );
};

export default Intro;
